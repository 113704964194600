import React from "react";
import { ExternalLinkIcon } from "@radix-ui/react-icons";

const Shortcut = ({ name, url }) => {
  const handleMouseOver = () => {
    console.log("Hovered over " + name);
  };

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      onMouseOver={handleMouseOver}
      className="flex items-center p-4 mb-4 bg-primary text-white rounded-lg hover:bg-secondary transition transform hover:scale-105"
      style={{ fontFamily: "Poppins" }}
    >
      <ExternalLinkIcon className="mr-2" />
      {name}
    </a>
  );
};

export default Shortcut;
