import React from "react";

const DuckAnimation = () => {
  return (
    <div className="absolute bottom-0 left-0 w-full flex justify-around items-end h-40 overflow-hidden">
      {/* Bebek pertama */}
      <div className="w-20 h-20 animate-bounce-slow">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="yellow"
          className="w-full h-full"
        >
          <path d="M12 2a6 6 0 00-6 6 5.99 5.99 0 003.585 5.415c-.09.344-.201.7-.326 1.064-1.22 3.7-2.587 5.21-3.157 5.78C5.69 20.637 6 21 6.414 21H17.58c.42 0 .733-.367.514-.737-.57-.57-1.938-2.08-3.157-5.78a12.356 12.356 0 00-.326-1.064A5.99 5.99 0 0018 8a6 6 0 00-6-6zm0 1.5a4.5 4.5 0 014.5 4.5 4.49 4.49 0 01-3.48 4.356.75.75 0 00-.507.58c-.11.5-.238 1.022-.384 1.559-1.27 4.813-2.541 6.004-2.541 6.004S8.102 15.9 6.832 11.087c-.146-.537-.273-1.059-.384-1.56a.75.75 0 00-.507-.579A4.49 4.49 0 017.5 8c0-2.485 2.014-4.5 4.5-4.5zm-2.75 7.252a.75.75 0 010-1.502.75.75 0 010 1.502zm5.5 0a.75.75 0 010-1.502.75.75 0 010 1.502z" />
        </svg>
      </div>
      {/* Bebek kedua */}
      <div className="w-20 h-20 animate-bounce-slow animation-delay-200">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="yellow"
          className="w-full h-full"
        >
          <path d="M12 2a6 6 0 00-6 6 5.99 5.99 0 003.585 5.415c-.09.344-.201.7-.326 1.064-1.22 3.7-2.587 5.21-3.157 5.78C5.69 20.637 6 21 6.414 21H17.58c.42 0 .733-.367.514-.737-.57-.57-1.938-2.08-3.157-5.78a12.356 12.356 0 00-.326-1.064A5.99 5.99 0 0018 8a6 6 0 00-6-6zm0 1.5a4.5 4.5 0 014.5 4.5 4.49 4.49 0 01-3.48 4.356.75.75 0 00-.507.58c-.11.5-.238 1.022-.384 1.559-1.27 4.813-2.541 6.004-2.541 6.004S8.102 15.9 6.832 11.087c-.146-.537-.273-1.059-.384-1.56a.75.75 0 00-.507-.579A4.49 4.49 0 017.5 8c0-2.485 2.014-4.5 4.5-4.5zm-2.75 7.252a.75.75 0 010-1.502.75.75 0 010 1.502zm5.5 0a.75.75 0 010-1.502.75.75 0 010 1.502z" />
        </svg>
      </div>
      {/* Bebek ketiga */}
      <div className="w-20 h-20 animate-bounce-slow animation-delay-400">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="yellow"
          className="w-full h-full"
        >
          <path d="M12 2a6 6 0 00-6 6 5.99 5.99 0 003.585 5.415c-.09.344-.201.7-.326 1.064-1.22 3.7-2.587 5.21-3.157 5.78C5.69 20.637 6 21 6.414 21H17.58c.42 0 .733-.367.514-.737-.57-.57-1.938-2.08-3.157-5.78a12.356 12.356 0 00-.326-1.064A5.99 5.99 0 0018 8a6 6 0 00-6-6zm0 1.5a4.5 4.5 0 014.5 4.5 4.49 4.49 0 01-3.48 4.356.75.75 0 00-.507.58c-.11.5-.238 1.022-.384 1.559-1.27 4.813-2.541 6.004-2.541 6.004S8.102 15.9 6.832 11.087c-.146-.537-.273-1.059-.384-1.56a.75.75 0 00-.507-.579A4.49 4.49 0 017.5 8c0-2.485 2.014-4.5 4.5-4.5zm-2.75 7.252a.75.75 0 010-1.502.75.75 0 010 1.502zm5.5 0a.75.75 0 010-1.502.75.75 0 010 1.502z" />
        </svg>
      </div>
    </div>
  );
};

export default DuckAnimation;
