import React from "react";
import Shortcut from "./components/Shortcut";
import DuckAnimation from "./components/DuckAnimation";

function App() {
  const shortcuts = [
    {
      name: "Sistem Balitimbungan",
      url: "http://sistem.balitimbungan.id",
    },
    {
      name: "Web Kasir Mahakam",
      url: "http://sarinah.kerjaansyahrul.online:9280/",
    },
    {
      name: "Web Kasir Sarinah",
      url: "http://mahakam.kerjaansyahrul.online:9380/",
    },
    {
      name: "Network Video Recorder Mahakam",
      url: "http://mahakam.kerjaansyahrul.online:2002",
    },
    {
      name: "Network Video Recorder Sarinah",
      url: "http://sarinah.kerjaansyahrul.online:2001/",
    },
    {
      name: "Helpdesk & Asset System",
      url: "http://help.balitimbungan.id",
    },
    {
      name: "Google Form Petty Cash",
      url: "https://docs.google.com/forms/d/e/1FAIpQLSedA_AEF70xM9nWygC3bzyEvYwoGY2jtOg4XZ-0hhpsBsaG3Q/viewform",
    },
  ];

  return (
    <div
      className="relative min-h-screen bg-gray-50 flex flex-col items-center justify-center"
      style={{ fontFamily: "Poppins" }}
    >
      <h1 className="text-3xl font-bold mb-8 text-primary">
        Shortcut Website PT Bali Boga Natura
      </h1>
      <div className="space-y-4">
        {shortcuts.map((shortcut, index) => (
          <Shortcut key={index} name={shortcut.name} url={shortcut.url} />
        ))}
      </div>
      <DuckAnimation />
    </div>
  );
}

export default App;
